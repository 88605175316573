//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "message",
  data() {
    return {
      type: this.$route.query.type || 0
    };
  },

  methods: {}
};
